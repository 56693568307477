<template>
  <div class="payment">
    <img
      src="@/static/svg/page-gradient.svg"
      class="payment__gradient"
    >

    <div class="payment__view">
      <Header simple />

      <div
        v-if="data"
        class="payment__view__center"
      >
        <div class="payment__view__center__content">
          <div
            v-if="data.status !== 'expired' && data.status !== 'payed'"
            class="payment__view__center__content__reload"
          >
            <div class="payment__view__center__content__reload__progress">
              <div
                class="payment__view__center__content__reload__progress__bar"
                :style="{
                  width: `${_progressRefresh}%`
                }"
              />
            </div>

            <div class="payment__view__center__content__reload__text">
              {{ $t('views.payment.refresh', { value: refreshTimer }) }}
            </div>
          </div>

          <div class="payment__view__center__content__nav">
            <BackButton />
          </div>

          <div class="payment__view__center__content__start">
            <template
              v-if="data.status === 'expired'"
            >
              <h2>
                {{ $t('views.payment.payment-expired') }}
              </h2>

              <p>
                {{ $t('views.payment.payment-expired-text') }}
              </p>
            </template>

            <template
              v-else-if="data.status === 'payed'"
            >
              <h2>
                {{ $t('views.payment.paymend-payed') }}
              </h2>

              <p>
                {{ $t('views.payment.paymend-payed-text') }}
              </p>
            </template>

            <template
              v-else
            >
              <h2
                v-if="!isError"
              >
                {{ $t('views.payment.payment-pending') }}
              </h2>

              <h2
                v-else
              >
                {{ $t('views.payment.payment-processing') }}
              </h2>

              <p>
                {{ $t('views.payment.payment-commission') }}
              </p>

              <p>
                {{ $t('views.payment.payment', { slug: data.payment_data.currency_slug, name: data.payment_data.currency_name }) }}
              </p>
            </template>
          </div>

          <div class="container__content__flex">
            <div class="container__content__flex__start">
              <div class="container__content__flex__start__row top">
                <div class="container__content__flex__start__row__left">
                  <p class="little-text">
                    {{ $t('views.payment.order') }}
                  </p>

                  <p class="content">
                    ID: {{ $route.params.order }}
                  </p>
                </div>
              </div>

              <div class="container__content__flex__start__row top">
                <div class="container__content__flex__start__row__left">
                  <p class="little-text">
                    {{ $t('views.payment.address') }}
                  </p>

                  <p
                    class="content"
                    :class="{
                      error: data.status === 'expired'
                    }"
                  >
                    {{ data.payment_data.address }}
                  </p>
                </div>

                <button
                  class="copy"
                  :class="{
                    active : isCopy
                  }"
                  @click="copy()"
                >
                  <svg
                    v-if="!isCopy"
                    width="18" height="18"
                    style="min-width: 18px; min-height: 18px"
                    viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1331_24973)">
                      <path d="M15.4522 17.4287H6.48731C5.39677 17.4287 4.50977 16.5417 4.50977 15.4512V6.48633C4.50977 5.3958 5.39677 4.50879 6.48731 4.50879H15.4522C16.5427 4.50879 17.4297 5.3958 17.4297 6.48633V15.4512C17.4297 16.5417 16.5427 17.4287 15.4522 17.4287ZM6.48731 5.82715C6.12383 5.82715 5.82813 6.12285 5.82813 6.48633V15.4512C5.82813 15.8146 6.12383 16.1104 6.48731 16.1104H15.4522C15.8156 16.1104 16.1113 15.8146 16.1113 15.4512V6.48633C16.1113 6.12285 15.8156 5.82715 15.4522 5.82715H6.48731ZM3.19141 12.1553H2.53223C2.16875 12.1553 1.87305 11.8596 1.87305 11.4961V2.53125C1.87305 2.16777 2.16875 1.87207 2.53223 1.87207H11.4971C11.8605 1.87207 12.1563 2.16777 12.1563 2.53125V3.15747H13.4746V2.53125C13.4746 1.44072 12.5876 0.553711 11.4971 0.553711H2.53223C1.4417 0.553711 0.554688 1.44072 0.554688 2.53125V11.4961C0.554688 12.5866 1.4417 13.4736 2.53223 13.4736H3.19141V12.1553Z" fill="#202539"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1331_24973">
                        <rect width="18" height="18" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>

                  <svg v-else width="20" height="15" style="min-width: 20px; min-height: 15px" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 6.28571L7.81818 12L18 2" stroke="#D3FF53" stroke-width="3" stroke-linecap="round"/>
                  </svg>
                </button>
              </div>

              <div class="container__content__flex__start__row bottom">
                <div class="flex">
                  <p class="small-text">
                    {{ $t('views.payment.to-pay') }}:
                  </p>

                  <p class="content">
                    {{
                      $format.wallet(
                        data.payment_data?.currency_slug === 'USDT' || data.payment_data?.currency_slug === 'USDC' ? parseInt(data.amount)
                          : data?.amount, ',')
                    }} {{ data.payment_data?.currency_slug }}
                  </p>
                </div>

                <button
                  class="copy"
                  :class="{
                    active : isCopyVal
                  }"
                  @click="
                    copyValue(
                      data.payment_data?.currency_slug === 'USDT'
                        || data.payment_data?.currency_slug === 'USDC' ? parseInt(data?.amount)
                          : data?.amount
                    )
                  "
                >
                  <svg
                    v-if="!isCopyVal"
                    width="18" height="18"
                    style="min-width: 18px; min-height: 18px"
                    viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1331_24973)">
                      <path d="M15.4522 17.4287H6.48731C5.39677 17.4287 4.50977 16.5417 4.50977 15.4512V6.48633C4.50977 5.3958 5.39677 4.50879 6.48731 4.50879H15.4522C16.5427 4.50879 17.4297 5.3958 17.4297 6.48633V15.4512C17.4297 16.5417 16.5427 17.4287 15.4522 17.4287ZM6.48731 5.82715C6.12383 5.82715 5.82813 6.12285 5.82813 6.48633V15.4512C5.82813 15.8146 6.12383 16.1104 6.48731 16.1104H15.4522C15.8156 16.1104 16.1113 15.8146 16.1113 15.4512V6.48633C16.1113 6.12285 15.8156 5.82715 15.4522 5.82715H6.48731ZM3.19141 12.1553H2.53223C2.16875 12.1553 1.87305 11.8596 1.87305 11.4961V2.53125C1.87305 2.16777 2.16875 1.87207 2.53223 1.87207H11.4971C11.8605 1.87207 12.1563 2.16777 12.1563 2.53125V3.15747H13.4746V2.53125C13.4746 1.44072 12.5876 0.553711 11.4971 0.553711H2.53223C1.4417 0.553711 0.554688 1.44072 0.554688 2.53125V11.4961C0.554688 12.5866 1.4417 13.4736 2.53223 13.4736H3.19141V12.1553Z" fill="#202539"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_1331_24973">
                        <rect width="18" height="18" fill="white"/>
                      </clipPath>
                    </defs>
                  </svg>

                  <svg v-else width="20" height="15" style="min-width: 20px; min-height: 15px" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M2 6.28571L7.81818 12L18 2" stroke="#D3FF53" stroke-width="3" stroke-linecap="round"/>
                  </svg>
                </button>
              </div>

              <div class="container__content__flex__start__row bottom">
                <p class="small-text">
                  {{ $t('views.payment.received') }}:
                </p>

                <p
                  class="content"
                  :class="{
                    error: data.received > 0 && data.received < data?.amount
                  }"
                >
                  {{
                    $format.wallet(
                      data.payment_data?.currency_slug === 'USDT' || data.payment_data?.currency_slug === 'USDC' ? parseInt(data.received)
                        : data.received, ',')
                  }} {{ data.payment_data?.currency_slug }}
                </p>
              </div>

              <template
                v-if="data.status !== 'expired' && data.status !== 'payed'"
              >
                <div
                  v-if="isError"
                  class="container__content__flex__start__error"
                >
                  <div class="container__content__flex__start__error__icon">
                    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M7 0H14V7L7 14H0V7L7 0Z" fill="#DA2828" fill-opacity="0.2"/>
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M14 14H7L0 21V28H7L14 21V28H21L28 21V14H21L28 7V0H21L14 7V14ZM14 14H21L14 21V14Z" fill="#DA2828" fill-opacity="0.2"/>
                      <g clip-path="url(#clip0_192_4514)">
                        <path d="M21 8.6042C21 7.71927 20.2807 7 19.3958 7H8.6042C7.71927 7 7 7.71927 7 8.6042V19.3958C7 20.2807 7.71927 21 8.6042 21H19.3958C20.2807 21 21 20.2807 21 19.3958V8.6042Z" fill="#DA2828"/>
                        <path d="M11.2002 11.2L16.8002 16.8M16.8002 11.2L11.2002 16.8" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_192_4514">
                          <rect width="14" height="14" fill="white" transform="translate(7 7)"/>
                        </clipPath>
                      </defs>
                    </svg>

                    {{ $t('views.payment.incomplete-payment') }}
                  </div>

                  <div class="container__content__flex__start__error__text">
                    1. {{ $t('views.payment.incomplete-payment-main') }}

                    <br>

                    <span>
                      {{ $t('views.payment.incomplete-payment-main-sub', { val: _lacks, slug: data.payment_data.currency_slug, name: data.payment_data.currency_name }) }}
                    </span>
                  </div>

                  <div
                    v-if="data.status === 'partial-payment-confirmation'"
                    class="container__content__flex__start__error__text margin"
                    v-html="$t('views.payment.incomplete-payment-1')"
                  />

                  <div class="container__content__flex__start__error__text margin">
                    2. {{ $t('views.payment.incomplete-payment-2', { val: data.received, slug: data.payment_data.currency_slug }) }}

                    <BlueButton
                      :text="$t('views.payment.incomplete-payment-button', { val: data.received })"
                      @click="approve()"
                      :loading="approveLoadeing"
                    />
                  </div>
                </div>

                <div
                  class="container__content__flex__start__info"
                  :class="{
                    error: isError
                  }"
                >
                  <div class="container__content__flex__start__info__info">
                    <div class="container__content__flex__start__info__info__icon">
                      <svg
                        width="4" height="14"
                        style="min-width: 4px; max-height: 14px;"
                        viewBox="0 0 4 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M0.959219 9.3403L0.799219 0H3.23922L3.09922 9.3403H0.959219ZM0.699219 11.1791H3.29922V14H0.699219V11.1791Z" fill="#202539"/>
                      </svg>
                    </div>

                    {{ $t('views.payment.important-information') }}
                  </div>

                  <ul>
                    <li>
                      {{ $t('views.payment.send') }} <span>{{ data.payment_data.currency_slug }}</span>
                    </li>

                    <li>
                      {{ $t('views.payment.verify') }}: <span>{{ data.payment_data.currency_name }}</span>
                    </li>

                    <li>
                      {{ $t('views.payment.make') }}
                    </li>
                  </ul>
                </div>
              </template>

              <div
                v-else
                class="container__content__flex__start__grid"
              >
                <BlueButton
                  :text="$t('views.payment.button-main')"
                  @click="$router.push({ name: 'Main' })"
                />

                <BlueButton
                  v-if="data.status === 'payed'"
                  class="light"
                  :text="$t('views.payment.button-orders')"
                  @click="$router.push({ name: 'TransactionsOrder' })"
                />
              </div>
            </div>

            <div class="container__content__flex__end">
              <div class="container__content__flex__end__card">
                <p class="small-text">
                  {{ $t('views.payment.network') }}
                </p>

                <p class="token">
                  {{ data.payment_data.currency_name }}
                </p>

                <QrCode
                  :status="data.status"
                  :value="data.payment_data.address"
                />
              </div>

              <p
                v-if="_countDown && _countDown !== 'Invalid Date'"
                class="small-text timer"
                :class="{
                  error: data.status === 'expired',
                  success: data.status === 'payed'
                }"
              >
                <template
                  v-if="data.status === 'expired'"
                >
                  {{ $t('views.payment.period-expired') }}
                </template>

                <template
                  v-else-if="data.status === 'payed'"
                >
                  {{ $t('views.payment.period-success') }}
                </template>

                <template
                  v-else
                >
                  {{ $t('views.payment.period') }}:

                  <br>

                  <span>
                    {{ _countDown }}
                  </span>
                </template>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/modules/app/Header.vue'
import BackButton from '@/ui/buttons/BackButton.vue'
import QrCode from '@/ui/QrCode.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

export default {
  components: {
    Header,
    BackButton,
    QrCode,
    BlueButton
  },
  data () {
    return {
      data: null,
      timer: null,
      isCopy: false,
      isCopyVal: false,
      countOut: 0,
      refresh: true,
      refreshTimer: 30,
      approveLoadeing: false
    }
  },
  computed: {
    _countDown () {
      const out = this.countOut
      const nowDate = new Date()
      const endDate = new Date(this.data.expired_at)

      const time = Date.parse(endDate) - (Date.parse(nowDate) + out)

      if (time <= 0) {
        // eslint-disable-next-line vue/no-async-in-computed-properties
        setTimeout(() => {
          clearInterval(this.timer)
        }, 600000)

        return '00:00:00'
      }

      const hours = this._formatTime(time > 0 ? Math.floor(time / 1000 / 60 / 60) % 24 : 0)
      const minutes = this._formatTime(time > 0 ? Math.floor(time / 1000 / 60) % 60 : 0)
      const seconds = this._formatTime(time > 0 ? Math.floor(time / 1000) % 60 : 0)

      return `${hours}:${minutes}:${seconds}`
    },
    _progressRefresh () {
      const minus = 30 - this.refreshTimer
      return minus / (29 / 100)
    },
    isError () {
      const data = this.data
      return data.received > 0 && data.received < Number(data?.amount)
    },
    _lacks () {
      const data = this.data
      return Number(data?.amount) - Number(data.received)
    }
  },
  mounted () {
    this.$store.commit('PRELOADER_GLOBAL_EDIT', true)
    this.fetch()
  },
  beforeUnmount () {
    clearInterval(this.timer)
  },
  watch: {
    refreshTimer () {
      if (this.refreshTimer === 1 && this.refresh) {
        this.fetch()

        if (!this._countDown || this._countDown === 'Invalid Date') {
          this.refresh = false
        }
      }
    }
  },
  methods: {
    async fetch () {
      try {
        const resp = await this.$axios.get(
          `/private/checkout/payment-data/${this.$route.params.order}`)

        if (resp.status === 200) {
          this.data = resp.data.data

          if (this.timer === null) {
            this.startTimer()
          }
        }
      } catch (err) {
        throw new Error(err)
      } finally {
        this.$store.commit('PRELOADER_GLOBAL_EDIT', false)
      }
    },
    async approve () {
      try {
        this.approveLoadeing = true
        this.$store.commit('PRELOADER_GLOBAL_EDIT', true)

        const resp = await this.$axios.post(
          `private/checkout/approve-partial-payment/${this.$route.params.order}`)

        if (resp.status === 200) {
          if (resp.data.success) {
            this.data.status = 'payed'
          } else {
            this.data.status = 'expired'
          }
        }
      } catch (err) {
        this.data.status = 'expired'
        throw new Error(err)
      } finally {
        this.approveLoadeing = false
        this.$store.commit('PRELOADER_GLOBAL_EDIT', false)
      }
    },
    _formatTime (val) {
      if (val > 9) {
        return val
      } else {
        return `0${val}`
      }
    },
    startTimer () {
      this.timer = setInterval(() => {
        this.countOut += 1

        if (this.refreshTimer === 1) {
          this.refreshTimer = 30
        } else {
          this.refreshTimer -= 1
        }
      }, 1000)
    },
    copy () {
      this.isCopy = true
      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = this.data.payment_data.address

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.isCopy = false
      }, 4000)
    },
    copyValue (val) {
      this.isCopyVal = true
      var copyTextarea = document.createElement('textarea')
      copyTextarea.style.position = 'fixed'
      copyTextarea.style.height = '1px'
      copyTextarea.style.width = '1px'
      copyTextarea.style.opacity = '0'
      copyTextarea.textContent = val

      document.body.appendChild(copyTextarea)
      copyTextarea.select()
      document.execCommand('copy')
      document.body.removeChild(copyTextarea)

      setTimeout(() => {
        this.isCopyVal = false
      }, 4000)
    }
  }
}
</script>

<style lang="scss" scoped>
.payment {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 0 10px 40px;
  background: rgb(var(--layout-color));

  img.payment__gradient {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .payment__view {
    position: relative;
    height: 100%;
    width: 100%;
    z-index: 2;

    .payment__view__center {
      padding: 30px 0;
      display: flex;
      align-items: center;
      justify-content: center;

      .payment__view__center__content {
        width: 100%;
        max-width: 900px;

        .payment__view__center__content__reload {
          width: 100%;
          margin-bottom: 20px;

          .payment__view__center__content__reload__progress {
            height: 4px;
            width: 100%;
            background: rgba(47, 87, 233, .2);
            border-radius: 20px;
            overflow: hidden;

            .payment__view__center__content__reload__progress__bar {
              height: 100%;
              background: #2F57E9;
              border-radius: inherit;
              transition: .5s;
            }
          }

          .payment__view__center__content__reload__text {
            margin-top: 10px;
            font-family: Medium;
            font-size: 12px;
            line-height: 100%;
            color: #787C8D;
            text-align: center;
          }
        }

        .payment__view__center__content__nav {
          margin-bottom: 70px;
          display: flex;
          justify-content: flex-end;
        }

        .payment__view__center__content__start {
          margin-bottom: 80px;
          text-align: center;

          h2 {
            margin-bottom: 30px;
            line-height: 100%;
            color: #202539;
            font-family: SemiBold;
          }

          p {
            margin-top: 15px;
            line-height: 100%;
            color: #787C8D;
            font-family: Medium;
          }
        }
      }
    }
  }
}

.container__content__flex {
  margin-top: 40px;
  display: flex;

  .container__content__flex__start {
    width: 100%;
    margin-right: 40px;

    .container__content__flex__start__row {
      height: auto;
      min-height: 60px;
      padding-top: 10px !important;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      border-bottom: 1px solid rgba(156, 163, 184, .6);

      &.top {
        padding: 0 0 10px;
      }

      &.bottom {
        padding: 0 0 14px;
        align-items: center;

        p.small-text {
          line-height: 100%;
          color: #787C8D;
          font-family: Medium;
        }

        p.content {
          font-size: 18px;
          line-height: 100%;
          color: #202539;
          font-family: SemiBold;

          &.error {
            color: #FF0F00;
          }
        }

        .flex {
          width: 100%;
          display: flex;
          justify-content: space-between;
        }
      }

      .container__content__flex__start__row__left {
        font-family: Medium;
        margin-right: 15px;
        overflow: hidden;

        p.little-text {
          margin-bottom: 6px;
          line-height: 100%;
          color: #787C8D;
          font-family: inherit;
        }

        p.content {
          width: 300px;
          max-width: 300px;
          font-size: 14px;
          line-height: 100%;
          color: #202539;
          font-family: inherit;
          overflow-wrap: break-word;

          &.error {
            color:#DA2828;
          }
        }
      }

      button.copy {
        height: 36px;
        width: 36px;
        min-height: 36px;
        min-width: 36px;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(47, 87, 233, .2);

        &.active {
          background: rgb(var(--color-dark));
        }
      }
    }

    .container__content__flex__start__grid {
      margin-top: 50px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 15px;
    }

    .container__content__flex__start__error {
      margin: 30px 0;
      padding: 15px;
      background: rgba(233, 47, 47, .1);
      border-radius: 10px;

      .container__content__flex__start__error__icon {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 100%;
        color: #FF0F00;
        font-family: SemiBold;

        svg {
          margin-right: 10px;
        }
      }

      .container__content__flex__start__error__text {
        font-size: 14px;
        line-height: 150%;
        color: #202539;

        &.margin {
          margin-top: 20px;
        }

        span {
          margin-top: 10px;
          display: block;
          font-family: SemiBold;
          color: inherit;
        }

        a {
          color: #2F57E9;
          text-decoration: underline;
        }

        button {
          max-width: 285px;
          margin-top: 20px;
        }
      }
    }

    .container__content__flex__start__info {
      width: 100%;
      margin-top: 40px;
      padding: 15px;
      background: rgba(47, 87, 233, .1);
      border-radius: 10px;

      &.error {
        margin: 0;
      }

      .container__content__flex__start__info__info {
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 100%;
        color: #202539;
        font-family: SemiBold;

        .container__content__flex__start__info__info__icon {
          height: 28px;
          width: 28px;
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background: #D3FF53;
        }
      }

      ul {
        margin: 15px 0 0;
        padding-left: 25px !important;

        li {
          font-size: 16px;
          line-height: 150%;
          color: #202539;
          font-family: Medium;

          span {
            font-family: SemiBold;
          }
        }
      }
    }
  }

  .container__content__flex__end {
    .container__content__flex__end__card {
      padding: 20px 37px 37px;
      background: #FFFFFF;
      box-shadow: 0px 5px 30px rgba(0, 0, 0, .06);
      border-radius: 15px;

      p {
        line-height: 100%;
        text-align: center;

        &.small-text {
          color: #787C8D;
          font-family: Medium;
        }

        &.token {
          margin: 10px 0 20px;
          font-size: 18px;
          color: #202539;
          font-family: SemiBold;
        }
      }
    }

    p.timer {
      margin-top: 20px;
      text-align: center;
      line-height: 100%;
      color: #787C8D;
      font-family: Medium;

      &.error {
        color: #FF0000;
      }

      &.success {
        color: #2F57E9;
      }

      span {
        margin-top: 3px;
        display: block;
        line-height: 100%;
        color: #2F57E9;
        font-family: Medium;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .payment {
    .payment__view {
      .payment__view__center {
        padding: 30px 7px;

        .payment__view__center__content {
          .payment__view__center__content__nav {
            margin-bottom: 30px;
            justify-content: flex-start;
          }

          .payment__view__center__content__start {
            margin-bottom: 40px;
            text-align: start;

            h2 {
              margin-bottom: 15px;
            }

            p {
              line-height: 100%;
              color: #787C8D;
              font-family: Medium;
            }
          }
        }
      }
    }
  }

  .container__content__flex__start__error__text {
    button {
      max-width: 100% !important;
    }
  }

  .container__content__flex {
    flex-direction: column-reverse;
    align-items: center;

    .container__content__flex__start {
      margin-right: 0;

      .container__content__flex__start__row {
        height: auto;
        min-height: 60px;

        .container__content__flex__start__row__left {
          p.content {
            width: 100%;
            max-width: calc(100vw - 100px);
            font-size: 14px;
            line-height: 100%;
            color: #202539;
            font-family: inherit;
            overflow-wrap: break-word;
          }
        }
      }

      .container__content__flex__start__grid {
        grid-template-columns: repeat(1, 1fr);
        gap: 15px;

        button {
          max-width: 100% !important;
        }
      }
    }

    .container__content__flex__end {
      margin-bottom: 40px;

      .container__content__flex__end__card {
        margin: 0 auto;
      }
    }
  }
}
</style>

<style lang="scss">
.container__content__flex__start__error__text {
  font-size: 14px;
  line-height: 150%;
  color: #202539;

  &.margin {
    margin-top: 20px;
  }

  span {
    margin-top: 10px;
    display: block;
    font-family: SemiBold;
    color: inherit;
  }

  a {
    color: #2F57E9;
    text-decoration: underline;
  }
}
</style>
